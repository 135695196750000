.opWrapper .blocker, .opWrapperMessageBlocker {
  display: none;
  position: fixed;
  left: 0px;
  right: 0px;
  box-sizing: content-box;
  background: rgba(23, 20, 42, 0.90);
  z-index: 200;
  opacity: 1;
  top: 0;
  bottom: 0;
  min-height: 150%;
  pointer-events: all;
}

#messageView {
  z-index: 20000;
  font-size: 22px;
}

#messageView .messageContainer {
  max-width: 96%;
  min-width: 80%;
  position: fixed;
  top: 50%;
  left: 50%;
  user-select: none;
  box-sizing: content-box;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: #29263C;
  border-radius: 0.8rem;
  /*padding: 3rem 2rem 1rem 2rem;*/
  font-family: 'Inter', 'Ubuntu bold', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: initial;
  display: flex;
  flex-direction: column;
}

#messageView .messageTextContainer {
  box-sizing: content-box;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 1.66rem 3.3rem;
  gap: 1.66rem;
}

#messageView .messageTextContainer > svg {
  width: 10rem;
  height: 10rem;
}

#messageView .messageLine {
  width: 100%;
  height: 1px;
  opacity: 0.1;
  background: #FFF;
}
#messageView .messageButtonContainer {
  box-sizing: content-box;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 3.3rem;
  gap: 1.66rem;
}

#messageView #messageTitle {
  font-size: 3.3rem;
  color: rgb(255, 255, 255);
}

#messageView #messageDesc {
  font-size: 3.3rem;
  font-weight: 100;
  word-break: break-word;
  color: rgb(255, 255, 255);
}

#messageView #buttonOk, #messageView #customButton {
  padding: 2rem;
  background-color: #76CD33;
  border-radius: 0.8rem;
  color: white;
  pointer-events: all;
  cursor: pointer;
  min-width: 53%;
  font-size: 2rem;
}

#messageView #customButton {
  display: none;
  text-decoration: none;
}

#messageView #buttonOk:hover, #messageView #customButton:hover {
  background-color: #50A312;
}

#messageView #buttonOk:active, #messageView #customButton:active {
  background-color: #4B841F;
}

@media screen and (orientation: landscape){
  #messageView .messageContainer {
    min-width: 30%;
    transform: translate(-50%, -50%) scale(60%);
  }

}
