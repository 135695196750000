@font-face {
  font-family: 'lobster';
  src:  url(assets/fonts/Lobster.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'SegoeUI';
  src:  url("assets/fonts/Segoe UI Bold.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Ubuntu';
  src: url(assets/fonts/Ubuntu-Bold.eot);
  src: url(assets/fonts/Ubuntu-Bold.eot?#iefix) format('embedded-opentype'),
  url(assets/fonts/Ubuntu-Bold.woff2) format('woff2'),
  url(assets/fonts/Ubuntu-Bold.woff) format('woff'),
  url(assets/fonts/Ubuntu-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Inter Semi Bold';
  src: url(assets/fonts/Inter-SemiBold.woff2),
  url(assets/fonts/Inter-SemiBold.woff),
  url(assets/fonts/Inter-SemiBold.ttf);
  font-display: block;
}

@font-face {
  font-family: 'Inter Extra Bold';
  src: url(assets/fonts/Inter-ExtraBold.woff2),
  url(assets/fonts/Inter-ExtraBold.woff),
  url(assets/fonts/Inter-ExtraBold.ttf);
  font-display: block;
}

